import { PageProps } from "gatsby";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Hero } from "../components";
import { Layout } from "../layouts";
import { Helmet } from "react-helmet";
import classNames from "classnames";

const pagesInPager = 10;
const resultsPerPage = 10;
interface PageInfo {
  pageNumber: number;
  start: number;
}
function buildPager(start: number, allResults: number) {
  const pages: PageInfo[] = [];
  const totalResults = Math.min(99, allResults);

  const totalPages = Math.ceil(totalResults / resultsPerPage);
  const currentPage = 1 + Math.floor(start / resultsPerPage);
  const pagesToShow = Math.min(pagesInPager, totalPages);
  const halfPages = Math.ceil(pagesToShow / 2);

  const startPage =
    totalPages < pagesInPager || currentPage < halfPages
      ? 1
      : currentPage > totalPages - halfPages
      ? totalPages - (pagesToShow - 1)
      : currentPage - halfPages;

  const endPage = startPage + (pagesToShow - 1);

  for (let i = startPage; i <= endPage; i++) {
    pages.push({ pageNumber: i, start: (i - 1) * resultsPerPage });
  }
  return pages;
}

interface GapiSearch {
  search: typeof gapi.client.customsearch;
}

const src = "https://apis.google.com/js/api.js";

const SearchPage: React.FC<PageProps> = ({ path, location }) => {
  const query = useMemo(() => {
    const search = location.search;
    const params = new URLSearchParams(search);
    return params.get("query") || undefined;
  }, [location]);
  const [searching, setSearching] = useState(true);
  const [search, setSearch] = useState<gapi.client.customsearch.CseResource>();
  const [results, setResults] = useState<gapi.client.customsearch.Search>();
  const [error, setError] = useState<string>();
  const [start, setStart] = useState(0);

  useEffect(() => setStart(0), [query]);

  const scriptLoaded = useCallback(() => {
    gapi.load("client", () => {
      gapi.client.setApiKey("AIzaSyAnNTG7yZnF1eDGqZQ800Uxv41FvqDOeiM");
      gapi.client.load("customsearch", "v1", () => {
        const search = (gapi.client as unknown as GapiSearch).search;
        setSearch(search.cse);
      });
    });
  }, []);

  useEffect(() => {
    if (window?.gapi) {
      scriptLoaded();
    } else {
      const handle = window.setInterval(() => {
        if (window?.gapi) {
          window.clearInterval(handle);
          scriptLoaded();
        }
      }, 30);
      return () => window.clearInterval(handle);
    }
  }, [scriptLoaded]);

  useEffect(() => {
    if (search && query) {
      setSearching(true);
      search
        .list({
          cx: "43e5bd4e8b4c941c1",
          q: query,
          hl: "en",
          num: resultsPerPage,
          start,
          // dateRestrict: `m6`,
        })
        .then((response) => {
          if (response.status === 200) {
            if (
              response.result.searchInformation?.totalResults === "0" &&
              start > 0
            ) {
              const possibleStartIndex =
                response.result?.queries?.previousPage?.[0]?.startIndex;
              setStart(possibleStartIndex || 0);
              return;
            }
            setResults(response.result);
          } else {
            setError(response.statusText);
          }
          setSearching(false);
        })
        .catch((error) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          if (error.message) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            setError(error.message as string);
          } else {
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            const message = "Error: " + error;
            setError(message);
          }
          setSearching(false);
        });
    }
  }, [query, search, start]);

  const totalResults = parseInt(
    results?.searchInformation?.totalResults || "0"
  );
  const pages = useMemo(
    () => buildPager(start, totalResults),
    [start, totalResults]
  );

  const emptyResults = totalResults === 0;

  return (
    <Layout
      pagePath={path}
      title="Search results"
      description="PIP Search results"
    >
      <Helmet script={[{ src }]} />
      <Hero
        short={true}
        title="Search results"
        showSearch={true}
        className={classNames("bg-light-blue", searching && "cursor-wait")}
      />
      <section
        data-component="Search results"
        className={classNames(
          searching && "cursor-wait",
          "py-section w-full relative page-grid auto-rows-auto overflow-hidden space-y-30px"
        )}
      >
        {error && <pre>{JSON.stringify(error, undefined, "  ")}</pre>}
        {emptyResults && searching && (
          <h2 className="col-start-2 col-span-8 h2">Searching for: {query}</h2>
        )}
        {emptyResults && !searching && (
          <h2 className="col-start-2 col-span-8 h2">No results for: {query}</h2>
        )}

        {results &&
          results.items?.map((result) => {
            return (
              <div
                className="col-start-2 col-span-12 lg:col-start-2 lg:col-span-7"
                key={result.link}
              >
                <a
                  className="read-more"
                  href={result.link}
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  dangerouslySetInnerHTML={{ __html: result.htmlTitle! }}
                />
                <div
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  dangerouslySetInnerHTML={{ __html: result.htmlSnippet! }}
                />
              </div>
            );
          })}
        {results && (
          <div className="col-start-2 col-span-12 lg:col-start-2 lg:col-span-7 flex gap-x-10px">
            {pages.map((i) => (
              <button
                key={i.pageNumber}
                onClick={() => setStart(i.start)}
                className={start === i.start ? "text-dark-blue" : "text-purple"}
              >
                {i.pageNumber}
              </button>
            ))}
          </div>
        )}
      </section>
    </Layout>
  );
};

export default SearchPage;
